import { Link } from "@remix-run/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useToggleFavoriteMutation } from "~/old-app/api/favorite";
import { DefaultImage, DefaultStoreImage } from "~/old-app/constants";
import { useCurrency } from "~/old-app/hooks/useCurrency";
import { useAuthSlice } from "~/old-app/redux/auth-slice";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import {
  FavFilledIcon,
  FavIcon,
  OrdersIcon,
  PlainStarIcon,
} from "../../assets/icons";

const CardBranch = ({
  showFav = true,
  item: branch,
  imgW = "full",
  imgH = "h-40",
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { currency_symbol } = useCurrency();
  const { accessToken } = useAuthSlice();
  const isLoggedIn = !!accessToken;
  const [favoriteToggle] = useToggleFavoriteMutation();
  const { isLoading: isRemoteConfigLoading } = useConfigSlice();

  const [isFav, setIsFav] = useState(!!branch?.is_favorite);

  useEffect(() => {
    setIsFav(!!branch?.is_favorite);
  }, [branch?.id]);

  const favoriteToggleHandler = () => {
    const toggleInfo = {
      item_id: branch.id,
      type: "branch",
    };
    favoriteToggle(toggleInfo)
      .unwrap()
      .then((data) => {
        toast.success(data.message);
        setIsFav(!isFav);
      });
  };

  let budgetLevel = "";
  if (branch?.budget_level > 0 && !isRemoteConfigLoading) {
    for (let i = 0; i < branch?.budget_level; i++) {
      budgetLevel += currency_symbol;
    }
  }

  return (
    <div className="w-full">
      <div
        className={`relative overflow-hidden group flex flex-col min-w-0 break-words bg-white
         w-full rounded-lg gap-2 border border-gray-80 `}
      >
        <Link
          to={`/${language}/branches/${branch?.slug || branch?.id}`}
          className={`relative flex items-center justify-center rounded-lg w-full ${imgH} md:w-${imgW} relative`}
        >
          <img
            alt="..."
            src={branch?.cover}
            className="w-full h-full object-cover rounded-top-lg align-middle"
            loading="lazy"
          />
          <img
            alt="..."
            src={branch?.logo || DefaultStoreImage}
            className="object-cover rounded-top-lg align-middle border-white border-4 w-20 h-20 rounded-full absolute bottom-0 start-4 transform  translate-y-1/2 z-10"
            loading="lazy"
            onError={(e) => {
              e.target.src = DefaultStoreImage;
              e.target.onerror = null;
            }}
          />

          <div className="bg-white/30  backdrop-blur-md w-full absolute bottom-0 start-0 p-2 end-0">
            <h3 className="text-base text-black capitalize overflow-hidden line-clamp-1 ms-24">
              {branch?.name}
            </h3>
          </div>
        </Link>
        <div className="p-3 flex flex-row gap-2 justify-end">
          <blockquote className="flex flex-col ">
            <div className="flex gap-3 ">
              <div className="flex gap-1 text-gray-200 items-center justify-center align-center rounded-full outline-none focus:outline-none">
                <OrdersIcon size={20} className="text-primary-500" />

                <p className="text-sm text-primary-500 leading-3">
                  {branch?.products_count || 0}
                </p>
                <p className="text-sm text-gray-400 leading-3">
                  {t("influencer.products")}
                </p>
              </div>
              <span className="flex items-center text-xs gap-1 px-2 py-1 rounded-full border border-primary-500 text-primary-900 bg-primary-50">
                <PlainStarIcon className="text-primary-600 me-1" />{" "}
                <span className="text-primary-800 font-bold">
                  {branch?.rate || 0}
                </span>
              </span>
            </div>
            {/* <p
              className="flex items-center text-xs text-gray-500 leading-3 capitalize overflow-hidden"
            >
              {budgetLevel}
              {branch?.budget_level > 0 && <DotIcon className="mx-1" />}
              {branch?.minimum_order_amount}{" "}
              {!isRemoteConfigLoading && currency_symbol} {t("branch.minimum")}{" "}
              {branch?.branch_categories && <DotIcon className="mx-1" />}{" "}
              {branch?.branch_categories}
            </p> */}
            {/* <p
              className={`flex items-center text-xs text-gray-500 leading-3 capitalize overflow-hidden
            
                `}
            >
              <ClockIcon className="me-1" />
              {branch?.delivery_time}
              <DotIcon className="mx-1" />
              {branch?.delivery_fee > 0 ? (
                <>
                  <BikeIcon className="me-1" />
                  {branch?.delivery_fee}{" "}
                  {!isRemoteConfigLoading && currency_symbol}
                </>
              ) : (
                <span className="flex items-center text-primary-500">
                  <BikeIcon className="me-1" />
                  {t("product.free")}
                </span>
              )}
            </p> */}
            <div className="flex gap-1 items-center">
              {branch?.tags.map((tag) => (
                <span
                  key={tag.id}
                  className="px-2 py-1 rounded-full text-xs whitespace-nowrap"
                  style={{
                    backgroundColor: tag.background_color,
                    color: tag.color,
                  }}
                >
                  {tag.name}
                </span>
              ))}
            </div>
          </blockquote>
          {showFav &&
            isLoggedIn &&
            (isFav ? (
              <div className="absolute top-2 end-2 flex items-center justify-center p-1 border border-1  rounded-md bg-white w-8 h-8 border-primary-400">
                <FavFilledIcon
                  onClick={favoriteToggleHandler}
                  className="text-primary-400 cursor-pointer"
                  size={18}
                />
              </div>
            ) : (
              <div className="absolute top-2 end-2 flex items-center justify-center p-1 border border-1  rounded-md bg-white w-8 h-8 border-primary-400">
                <FavIcon
                  onClick={favoriteToggleHandler}
                  className="text-primary-400 cursor-pointer"
                  size={18}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CardBranch;

{
  /* {!branch?.is_opening_now && (
            <div
              className="absolute w-full h-full"
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
            >
              <span className="absolute bottom-1.5 start-1.5 text-white bg-error-600 py-1 px-2 rounded-md">
                {t("branch.closed")}
              </span>
            </div>
          )} */
}
